<template>
  <div>
    <h1>Estados</h1>
<v-list v-if="estados.length > 0">
  <v-list-item v-for="estado in estados" :key="estado.nombre">
    <v-list-item-content>
      <v-list-item-title>
        <v-chip>{{ estado.id }}</v-chip>
        {{ estado.nombre }}
      </v-list-item-title>
      <v-list-item-subtitle>
        <strong>Transiciones: </strong>
        <v-chip-group>
          <div v-for="transicion in estado.transiciones" :key="transicion.tipo_comprobante">

            <strong>Anteriores:</strong> [{{ transicion.estados_antecesores.join(', ') }}] , 
            <strong>Siguientes:</strong> [{{ transicion.estados_siguientes.join(', ') }}]
          </div>
         
        </v-chip-group>
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-chip>{{ estado.ambito }}</v-chip>
    </v-list-item-action>

  </v-list-item>
</v-list>
    <div v-else>
      <v-btn @click="cargarPorDefecto">Cargar condiciones IVA default</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "CondicionesIva",
  data() {
    return {
      estados: [],
    };
  },
  methods: {
    cargarPorDefecto() {
      // Realizar la solicitud POST utilizando Axios para cargar las condiciones de IVA por defecto
      this.axios
        .get("/soporte/estados")
        .then((response) => {
          // Manejar la respuesta exitosa si es necesario
          this.estados = response.data;
        })
        .catch((error) => {
          // Manejar el error si es necesario
          console.error(
            "Error al cargar condiciones IVA default:",
            error.response.data
          );
        });
    },
  },
  created() {
    this.axios
      .get("/soporte/estados")

      .then((response) => {
        this.estados = response.data;
      })
      .catch((e) => {
        console.log(e);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: `${e}`,
          icon: "mdi-information",
          timeout: 3000,
        });
      });
  },
};
</script>

<style lang="scss" scoped>
</style>